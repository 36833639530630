import { Table } from "@cp/store/mixins";
import { email, required } from "@cp/utils/rules";
import { US_STATES } from "@cp/constants/countries";
import { modalAddon } from "@cp/store/addons";
import { wait } from "@cp/utils/promiseUtils";
import { get } from "@cp/utils/objectUtils";

import { Model } from "@/store/mixins/Model";
import search from "@/store/modules/search";

const LOCATION_TYPES = [
  {
    label: "Office",
    value: 1,
  },
  {
    label: "Property",
    value: 2,
  },
];

const employeeSearch = search.register("employees");
const locationSearch = search.register("locations");
const regionSearch = search.register("regions");
const reportingGroupSearch = search.register("reporting_groups");

export const location = new Model({
  module: "location",
  table: {
    headers: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Type",
        value: "location_type_id",
        width: "20px",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        width: "auto",
        cellClass: "linkedCell",
        width: "30%",
      },
      {
        text: "City",
        value: "city",
      },
      {
        text: "State",
        value: "state",
      },
      {
        text: "Region",
        value: "region",
      },
      {
        text: "Employees",
        value: "user_count",
        width: "120px",
      },
      {
        text: "Manager",
        value: "managers",
        sortable: false,
        width: "15%",
        cellClass: "text-truncate",
      },
      // {
      //   text: "Units",
      //   value: "unit_count",
      //   width: "10%",
      // },
      {
        text: "Status",
        value: "status_id",
        width: "100px",
      },
    ],
    filters: [
      "q_text",
      {
        label: "General",
        type: "select",
        key: "general",
        items: [
          {
            label: "Active",
            value: "active",
          },
          {
            label: "Archived",
            value: "archived",
          },
          {
            label: "Offices and Unlinked Properties",
            value: "offices_and_unlinked_properties",
          },
          {
            label: "No Manager Assigned",
            value: "missing_manager",
          },
          {
            label: "No Employees Assigned",
            value: "missing_employees",
          },
          {
            label: "No Region Assigned",
            value: "missing_region",
          },
          {
            label: "No Units Entered",
            value: "no_units",
          },
          {
            label: "Could not locate address",
            value: "no_coordinates",
          },
          {
            label: "Linked",
            value: "linked_view",
          },
          {
            label: "Not Linked",
            value: "unlinked_view",
          },
        ],
        initialValue: "active",
      },
      {
        label: "Regions",
        type: "sub_query",
        key: "regions",
        multiple: true,
      },
      {
        label: "Reporting Groups",
        type: "sub_query",
        key: "reporting_groups_by_locations",
        multiple: true,
      },
      {
        label: "Location Type",
        type: "sub_query",
        key: "location_types",
        items: [],
        multiple: true,
      },
    ],
    bulkActions: [
      {
        name: "assign_managers",
        label: "Bulk assign property manager",
        explanation: "Assign new manager for these locations:",
        fields: {
          manager_ids: { rules: [required], multiple: true },
          update_method: {
            label: "Remove previous assignments",
            initialValue: "append",
          },
        },
        modalComponent: "bluk-set-locations-manager",
        successMessage: "Managers updated!",
      },
      {
        name: "assign_maintenance_managers",
        label: "Bulk assign maintenance manager",
        explanation: "Assign new maintenance manager for these locations:",
        fields: {
          maintenance_manager_ids: { rules: [required], multiple: true },
          update_method: {
            label: "Remove previous assignments",
            initialValue: "append",
          },
        },
        modalComponent: "bluk-set-locations-maintenance-manager",
        successMessage: "Maintenance managers updated!",
      },
      {
        name: "assign_groups",
        moduleKey: "bulkAssignRegion",
        label: "Bulk assign region",
        explanation: "Assign new region to these locations:",
        fields: {
          geographical_group_ids: { rules: [required] },
        },
        modalComponent: "bluk-set-locations-region",
        successMessage: "Regions updated!",
      },
      {
        name: "assign_groups",
        moduleKey: "bulkAssignReportingGroup",
        label: "Bulk assign reporting group",
        explanation: "Assign new reporting group to these locations:",
        fields: {
          geographical_group_ids: { rules: [required] },
          update_method: {
            label: "Remove previous assignments",
            initialValue: "append",
          },
        },
        modalComponent: "bluk-set-locations-reporting-group",
        successMessage: "Reporting groups updated!",
      },
      {
        name: "link",
        showIf: selected => {
          const selectedOneOffice =
            selected.filter(({ location_type }) => location_type === "office")
              .length === 1;
          const hasSelectedSomeProperties =
            selected.filter(({ location_type }) => location_type === "property")
              .length > 0;
          return selectedOneOffice && hasSelectedSomeProperties;
        },
        width: "700",
        label: "Link locations",
        hideChips: true,
        modalComponent: "bulk-link-locations",
        successMessage: "Locations linked!",
      },
      {
        // never shows in the menu, only used in LinkedLocations.vue
        name: "unlink",
        showIf: () => false,
        successMessage: "Locations unlinked!",
      },
    ],
  },
  form: {
    initialValue: {
      id: "",
      client_id: "",
      name: "",
      slug: "",
      address: "",
      address_2: null,
      country: "",
      city: "",
      state: "",
      zip: "",
      latitude: "",
      longitude: "",
      archived_at: null,
      email: "",
      phone: null,
      created_at: "",
      location_status_id: 1,
      updated_at: "",
      location_manager_ids: [],
      reference_id: "",
      region_id: "",
      year_build: null,
      website: null,
      location_type_id: 1,
      linked_locations: [],
      status_id: 1,
      location_type: "",
      location_id: "",
      manager_ids: [],
      managers: [],
      maintenance_manager_ids: [],
      maintenance_managers: [],
      user_count: 0,
      unit_count: 0,
      region_name: "",
      region: {},
      groups: [],
      group_ids: [],
      deficiencies: [],
    },
    fields: {
      name: {
        rules: [required],
      },
      reference_id: {
        label: "Location ID",
      },
      location_type_id: {
        label: "Location Type",
        type: "autocomplete",
        rules: [required],
        autoSelectFirst: true,
        items: LOCATION_TYPES,
        initialValue: 1,
      },
      address: {},
      address_2: { label: "Suite, Unit, Etc" },
      city: {},
      state: {
        type: "autocomplete",
        items: US_STATES,
        autoSelectFirst: true,
        filter: (item, queryText) => {
          const searchText = (item.label + item.value).toLocaleLowerCase();
          return searchText.indexOf(queryText.toLocaleLowerCase()) > -1;
        },
      },
      zip: {},
      unit_count: {
        // modes: ["import"], Kevin when do we want to see this field?
      },
      phone: {
        label: "Phone Number",
      },
      email: {
        label: "Location Email",
        rules: [email],
      },
      manager_ids: {
        label: "Location Manager",
        type: "api-autocomplete",
        searchModule: employeeSearch,
        multiple: true,
      },
      maintenance_manager_ids: {
        label: "Maintenance Manager",
        type: "api-autocomplete",
        searchModule: employeeSearch,
        multiple: true,
      },
      region_id: {
        label: "Region",
        type: "api-autocomplete",
        searchModule: regionSearch,
      },
      group_ids: {
        label: "Reporting Groups",
        type: "api-autocomplete",
        searchModule: reportingGroupSearch,
        multiple: true,
      },
    },
    fieldOrder: [
      "name",
      "reference_id",
      "location_type_id",
      "address",
      "address_2",
      "city",
      "state",
      "zip",
      "unit_count",
      "phone",
      "email",
      "manager_ids",
      "maintenance_manager_ids",
      "region_id",
      "group_ids",
    ],
  },
  search: locationSearch,
  detailTabs(item) {
    const itemType = item.location_type;
    const otherType = itemType === "property" ? "office" : "property";
    return ["general", otherType, "history"];
  },
});

export const linkItems = new Table({
  module: "location",
  baseUrl: location.table.client.axios.defaults.baseURL,
  headers: location.table.headers,
  name: "linkItems",
  tableOptions: {
    page: { size: 10 },
  },
  filters: [
    "q_text",
    {
      key: "location_types",
      type: "select",
      initialValue: [],
    },
    {
      key: "general",
      type: "checkbox",
      initialValue: "unlinked_view",
    },
  ],
  filtersOptions: {
    excludeKeysFromMenu: ["location_types", "general"],
  },
});
linkItems.add(
  modalAddon({
    modalName: "linkLocationDialog",
    modalKey: "linkLocationDialog",
    open({ state, dispatch }) {
      const filterParams = state[linkItems.keys.filterParams];
      filterParams.location_types.splice(
        0,
        filterParams.location_types.length,
        state.item.location_type_id === 1 ? 2 : 1
      );

      return dispatch(linkItems.keys.fetch);
    },
    async close({ commit }) {
      await wait(500);
      commit(this.keys.reset);
    },
  })
);

location.add(linkItems);

export const assignItems = new Table({
  module: "location",
  baseUrl: location.table.client.axios.defaults.baseURL,
  headers: location.table.headers,
  name: "assignItems",
  tableOptions: {
    page: { size: 10 },
  },
  filters: [
    "q_text",
    {
      key: "general",
      type: "checkbox",
      initialValue: "missing_region",
    },
    {
      key: "not_ids",
      type: "select",
      multiple: true,
      initialValue: [],
    },
  ],
  filtersOptions: {
    excludeKeysFromMenu: ["general"],
  },
});
assignItems.add(
  modalAddon({
    modalName: "assignLocationsDialog",
    modalKey: "assignLocationsDialog",
    open({ state, dispatch, rootState }, module) {
      const filterParams = state[assignItems.keys.filterParams];
      const item = get(rootState, module.form.p.s.stateKey);
      filterParams.not_ids = item.locations.map(x => x.id);
      if (module.mp === "reporting_group") {
        filterParams.general = "";
      } else {
        filterParams.general = "missing_region";
      }

      return dispatch(assignItems.keys.fetch);
    },
    async close({ commit }) {
      await wait(500);
      commit(this.keys.reset);
    },
  })
);

location.add(assignItems);

window.$location = location;

export default location.toVuex;
